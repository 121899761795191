const cacheInitalData = {
  selectedReservationId: 0,
  canContinueToReservation: false,
  finishReservation: false,
  basketModalOpen: false,
  clientFormValid: false,
  allBasketFiltersValid: false,
  deliveryFormValid: true,
  'reservation({})': {
    from: null,
    season: false,
    afternoonPickup: true,
    to: null,
    __typename: 'Reservation',
  },
  'basketSet({})': {
    __typename: 'BasketSet',
  },
  reservation_0: {
    id: 0,
    from: null,
    to: null,
    season: false,
    afternoonPickup: true,
    basket: {
      setIds: [],
      activeSetId: null,
      __typename: 'Basket',
    },
    customerId: 0,
    __typename: 'Reservation',
  },
  reservationHeaderLabel: '',
  priceSum: {
    rental: 0,
    bail: 0,
    delivery: 0,
    __typename: 'PriceSum',
  },
  getCustomerInfo: {
    id: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    streetName: null,
    streetNumber: null,
    city: null,
    zipCode: null,
    __typename: 'GetCustomerInfo',
  },
  pickupTimeId: null,
  addedToBasketModal: null,
  searchAllProducts: false,
  searchString: '',
  version: 12,
};

export default cacheInitalData;
